import React from "react"
import { Box, Grid, Heading, Container, Text, Flex } from "@theme-ui/components"
import RichContentStructuredText from "../richContentStructuredText"
import { MagicLink } from "../../utils/magicLink"
import { ArrowRight } from "react-feather"

const TitleAndBody = ({ title, body, link, darkBg }) => {
  return (
    <Box
      sx={{
        position: "relative",
        backgroundColor: darkBg ? "dark" : "primary",
      }}
    >
      <Container>
        <Grid
          columns={[1, 1, ".7fr .3fr"]}
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
            pb: [6],
            pt: [3],
          }}
          gap={[0]}
        >
          <Box>
            <Box>
              <Text
                as="h2"
                variant="h4"
                sx={{ textTransform: "unset" }}
                color={darkBg ? "primary" : "dark"}
              >
                {title}
              </Text>
            </Box>
            <Box sx={{ p: { color: "light", fontSize: [4] } }}>
              <RichContentStructuredText text={body} />
            </Box>
          </Box>
          <Box sx={{ textAlign: ["center","right"], mt:[4,0] }}>
            <MagicLink
              sx={{
                color: darkBg ? "light" : "dark",
                px: [3],
                py: [2],
                backgroundColor:"primary",
                fontWeight:"bold",
                border: "2px solid",
                display: "inline-block",
                borderColor: darkBg ? "light" : "dark",
                textDecoration: "none",
                "&:hover":{
                  backgroundColor: "light",
                  color: darkBg ? "dark" : "primary"
                }
              }}
              item={link}
            >
              <Flex sx={{ alignItems: "center" }}>
                {link.anchor}
                <Box sx={{ ml: 2 }}>
                  <ArrowRight />
                </Box>
              </Flex>
            </MagicLink>
          </Box>
        </Grid>
      </Container>
    </Box>
  )
}

export default TitleAndBody
