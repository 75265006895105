import React from "react"
import { graphql } from "gatsby"
import { Container, Heading, Box, Text, Flex } from "@theme-ui/components"
import Layout from "../components/layout"
import { getHomePath } from "../utils/path"
import { HelmetDatoCms } from "gatsby-source-datocms"
import LatestArticles from "../components/latestArticles"
import { useFavicon } from "../hooks/useFavicon"
import TitleAndBody from "../components/blocks/titleAndBody"
import OrderedList from "../components/blocks/orderedList"
import ImageAndText from "../components/blocks/imageAndText"
import ImagesAndText from "../components/blocks/imagesAndText"
import KickerTitle from "../components/blocks/kickerTitle"
import Accordion from "../components/blocks/accordion"
import { ArrowDown } from "react-feather"
import DoubleColumnList from "../components/blocks/doubleColumnList"
import RichContentStructuredText from "../components/richContentStructuredText"
import { GatsbyImage } from "gatsby-plugin-image"

const Home = ({ data: { page, site, articles } }) => {

  const favicon = useFavicon().site.faviconMetaTags;

  const i18nPaths = site.locales.map(locale => {
    return {
      locale: locale,
      value: getHomePath(locale),
    }
  })

  const blockIds = page.content.map(function (block) {
    if (block.id)
      return {
        id: block.id,
        title: block.kicker ? block.kicker : block.content.kicker,
      }
  }).filter((block) => {
    if (block && block.title)
      return block
  });



  return (
    <Layout locale={page.locale} pageSections={blockIds} i18nPaths={i18nPaths}>
      <HelmetDatoCms seo={page.seoMetaTags} favicon={favicon}>
        <html lang={page.locale} />
      </HelmetDatoCms>
      <Box
        sx={{
          minHeight: ["70vh"],
          height: ["auto", "auto"],
          position: "relative",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            height: ["100%"],
            width: "100%",
            zIndex: 0,
            backgroundColor: "dark",
            ".gatsby-image-wrapper": {
              height: ["100%"],
              width: "100%",
            },
          }}
        >
          {page.heroImage &&
            <Box
              sx={{
                height:"100%",
                ".gatsby-image-wrapper": {
                  height: "100%",
                },
              }}
            >
              <GatsbyImage image={page.heroImage.gatsbyImageData} alt="" />
            </Box>}
        </Box>

        <Container
          variant="oneSideRight"
          sx={{
            height: "100%",
            position: "relative",
            zIndex: 1,
            py:[5]
          }}
        >
          <Box
          >
            <Box>
              <Text as="p" variant="kicker">
                <span>{page.kicker}</span>
              </Text>
            </Box>
            <Box sx={{ width: "85%", margin: "0 auto", p: { textAlign: "center", color: "light" }, }}>
              <Heading as="h1" variant="h1" sx={{ textAlign: "center", fontWeight:"500", color: "light", fontSize: [5, 6] }}>{page.title}</Heading>
              <Box sx={{
                width: "95%", margin: "0 auto", p:{ my:6}, a: {
                  fontWeight:"bold",
                  display:"inline-block",
                  textDecoration:"none",
                  border:"2px solid",
                  color:"light",
                  px:[4],
                  py:[2],
                  "&:hover":{
                    backgroundColor: "light",
                    color: "dark"
                  }
                }
              }}>
                <RichContentStructuredText text={page.body} />
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
      {page.content && page.content.map(
        block =>
          block.model &&
          block.model.apiKey && (
            <Box as="section" id={block.id} key={block.id}>
              {block.model.apiKey === "title_and_body" && (
                <TitleAndBody
                  title={block.content.title}
                  body={block.content.body}
                  link={block.content.link}
                  darkBg={block.darkBackground}
                />
              )}
              {block.model.apiKey === "ordered_list" && (
                <OrderedList kicker={block.kicker} title={block.title} body={block.body} />
              )}
              {block.model.apiKey === "image_and_text" && (
                <ImageAndText
                  body={block.content}
                  image={block.content.image}
                  alignment={block.alignText}
                />
              )}
              {block.model.apiKey === "kicker_title" && (
                <KickerTitle
                  kicker={block.kicker}
                  title={block.title}
                />
              )}
              {block.model.apiKey === "accordion" && (
                <Accordion
                  kicker={block.kicker}
                  title={block.title}
                  items={block.items}
                />
              )}
              {block.model.apiKey === "double_column_list" && (
                <DoubleColumnList
                  kicker={block.kicker}
                  title={block.title}
                  list={block.list}
                />
              )}
              {block.model.apiKey === "images_and_text" && (
                <ImagesAndText
                  kicker={block.content.kicker} 
                  title={block.content.title} 
                  body={block.content.body}
                  images={block.images}
                  link={block.content.link}
                />
              )}
            </Box>
          ))
      }
    </Layout>
  )
}

export default Home

export const query = graphql`
  query HomeQuery($id: String!, $locale: String!) {
    page: datoCmsHomePage(id: { eq: $id }) {
      id
      title
      locale
      heroImage {
        gatsbyImageData(
          width: 1920
          placeholder: NONE
          forceBlurhash: false
        )
      }
      body {
        blocks
        value
      }
      content {
        ... on DatoCmsTitleAndBody {
          id
          content {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          darkBackground
          model {
            apiKey
          }
        }
        ... on DatoCmsOrderedList {
          kicker
          title
          body {
            blocks
            value
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsAccordion {
          id
          kicker
          title
          items: content {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsImageAndText {
          id
          content {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          alignText
          model {
            apiKey
          }
        }
        ... on DatoCmsKickerTitle {
          id
          kicker
          title
          model {
            apiKey
          }
        }
        ... on DatoCmsDoubleColumnList {
          id
          kicker
          title
          list {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsImagesAndText {
          id
          content {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          images {
            gatsbyImageData(width: 1480, placeholder: BLURRED, forceBlurhash: false)
          }
          model {
            apiKey
          }
        }
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    articles: allDatoCmsArticle(
      sort: { fields: meta___firstPublishedAt, order: DESC }
      filter: { slug: { ne: null }, locale: { eq: $locale } }
      limit: 6
    ) {
      nodes {
        ...ArticleDetails
        ...ArticleAllSlugLocales
        ...ArticleMeta
      }
    }
    site: datoCmsSite {
      locales
    }
  }
`
