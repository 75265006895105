import React from "react"
import { Box, Text, Grid, Heading, Container } from "@theme-ui/components"
import RichContentStructuredText from "../richContentStructuredText"

const OrderedList = ({ kicker, title, body }) => {
  return (
    <Box sx={{ backgroundColor: "light", py: [4,6] }}>
      <Container>
        <Box
          sx={{
            py: [4],
            textAlign: "center"
          }}
        >
          <Box>
            <Text as="div" variant="kicker">
              <span>{kicker}</span>
            </Text>
          </Box>
          <Heading variant="h2" color="dark" sx={{ fontWeight: "500" }}>{title}</Heading>
        </Box>
        <Box sx={{
          ol: {
            display:"grid",
            gridTemplateColumns: ["1fr","1fr","1fr 1fr 1fr 1fr 1fr"],
            gridGap:[4,3],
            li: { 
              display: "block!important" ,
              "&:before":{
                m:["0 auto",null],
                mb:[3],
              },
              textAlign: ["center","left"],
              fontWeight:"bold",
              fontSize:[3]
            }
          }
        }}>
          {body && <RichContentStructuredText text={body} theme={"light"} />}
        </Box>
      </Container>
    </Box>
  )
}

export default OrderedList
