import React from "react"
import { Box, Container, Text, Heading, Grid, Flex } from "@theme-ui/components"
import RichContentStructuredText from "../richContentStructuredText"
import { MagicLink } from "../../utils/magicLink"
import { ChevronRight } from "react-feather"

const DoubleColumnList = ({ kicker, title, list, customLink }) => {
  return (
    <Box sx={{ position: "relative", backgroundColor: "light" }}>
      <Box
        sx={{
          textAlign: "center",
        }}
      >
        <Box>
          <Text as="div" variant="kicker" sx={{ color: "light" }}>
            <span>{kicker}</span>
          </Text>
        </Box>
        <Text as="p" variant="p" color="dark" sx={{ fontWeight: "500" }}>
          {title}
        </Text>
      </Box>
      <Grid columns={[1, 2]} sx={{ py: [4,6] }}>
        {list.map((item, index) => (
          <Box>
            {index != 0 ? (
              <Text
                as="h4"
                variant="h4"
                color="primary"
                sx={{ fontWeight: "500" }}
              >
                {item.title}
              </Text>
            ) : (
              <Text
                as="h4"
                variant="h4"
                color="dark"
                sx={{ fontWeight: "500" }}
              >
                {item.title}
              </Text>
            )}
            <Box sx={{ px: [3] }}>
              <Box
                sx={{
                  ul: {
                    display: "block",
                    columns: [1, 1, 1, 1],
                    li: {
                      mb: [0],
                      color: "light",
                      p: {
                        color: "light",
                      },
                      "&:before": {
                        content: "''",
                        height: "6px",
                        minHeight: "6px",
                        maxHeight: "6px",
                        width: "6px",
                        minWidth: "6px",
                        maxWidth: "6px",
                        borderRadius: "full",
                        backgroundColor: "trasparent",
                        border: "1px solid",
                        borderColor: "light",
                        mr: [3],
                      },
                    },
                  },
                }}
              >
                <RichContentStructuredText text={item.body} />
              </Box>
              {item.link && (
                <Box sx={{ mt: [0, 2], py: [3] }}>
                  <MagicLink
                    item={item.link}
                    lcoale={item.locale}
                    sx={{
                      textDecoration: "none",
                      color: "dark",
                      fontWeight:"bold",
                      svg: {
                        stroke: "primary",
                      },
                      "&:hover": {
                        textDecoration: "underline",
                        ".icon-wrapper": {
                          backgroundColor: "primary",
                        },
                        svg: {
                          stroke: "light",
                        },
                      },
                    }}
                  >
                    <Flex sx={{ alignContent: "center", alignItems: "center" }}>
                      <Flex
                        className="icon-wrapper"
                        sx={{
                          mr: [2],
                          alignItems: "center",
                          justifyContent: "center",
                          p: [0],
                          border: "2px solid",
                          borderColor: "primary",
                          svg: { strokeWidth: "3" },
                        }}
                      >
                        <ChevronRight size={16} />
                      </Flex>
                      {item.link.anchor}
                    </Flex>
                  </MagicLink>
                </Box>
              )}
            </Box>
          </Box>
        ))}
      </Grid>
    </Box>
  )
}

export default DoubleColumnList
