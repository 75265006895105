import React from "react"
import { Box, Container, Text,Heading } from "@theme-ui/components"

const KickerTitle = ({ title, kicker }) => {
  return (
    <Box>
      <Container>
        <Box
          sx={{
            py: [4],
            textAlign: "center"
          }}
        >
          <Box>
            <Text as="div" variant="kicker">
              <span>{kicker}</span>
            </Text>
          </Box>
          <Heading variant="h2" color="dark" sx={{ fontWeight: "500" }}>{title}</Heading>
        </Box>
      </Container>
    </Box>
  )
}

export default KickerTitle
