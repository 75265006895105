import React from "react"
import { Box, Container, Text, Heading, Grid, Flex } from "@theme-ui/components"
import RichContentStructuredText from "../richContentStructuredText"
import { MagicLink } from "../../utils/magicLink"
import { ChevronRight } from "react-feather"

const DoubleColumnList = ({ kicker, title, list, theme = "dark" }) => {
  return (
    <Box sx={{ my:[6],position: "relative", backgroundColor: "primary", py:[4,6] }}>
      <Container>
        <Box
          sx={{
            py: [4],
            textAlign: "center",
          }}
        >
          <Box>
            <Text as="div" variant="kicker" sx={{ color: "light" }}>
              <span>{kicker}</span>
            </Text>
          </Box>
          <Heading variant="h2" color="dark" sx={{ fontWeight: "500" }}>
            {title}
          </Heading>
        </Box>
        <Grid columns={[1, 2]}>
          {list.map(item => (
            <Box>
              <Heading variant="h4" color="dark" sx={{ fontWeight: "500" }}>
                {item.title}
              </Heading>
              <Box sx={{ px: [3] }}>
                <Box
                  sx={{
                    ul: {
                      display: "block",
                      columns: [1, 1, 1, 1],
                      li: {
                        mb: [0],
                        color: "light",
                        p: {
                          color: "light",
                        },
                        "&:before": {
                          content: "''",
                          height: "6px",
                          minHeight: "6px",
                          maxHeight: "6px",
                          width: "6px",
                          minWidth: "6px",
                          maxWidth: "6px",
                          borderRadius: "full",
                          backgroundColor: "trasparent",
                          border: "1px solid",
                          borderColor: "light",
                          mr: [3],
                        },
                      },
                    },
                  }}
                >
                  <RichContentStructuredText text={item.body} />
                </Box>
                {item.link && (
                  <Box sx={{ mt: [0, 2], py: [3] }}>
                    <MagicLink
                      item={item.link}
                      lcoale={item.locale}
                      sx={{
                        textDecoration: "none",
                        color: "light",
                        fontWeight:"bold",
                        "&:hover": {
                          textDecoration: "underline",
                          ".icon-wrapper": {
                            backgroundColor: "light",
                          },
                          svg: {
                            stroke: "primary",
                          },
                        },
                      }}
                    >
                      <Flex
                        sx={{ alignContent: "center", alignItems: "center" }}
                      >
                        <Flex
                          className="icon-wrapper"
                          sx={{
                            mr: [2],
                            alignItems: "center",
                            justifyContent: "center",
                            p: [0],
                            border: "2px solid",
                            borderColor: "light",
                            svg: { strokeWidth: "3" },
                          }}
                        >
                          <ChevronRight size={16} />
                        </Flex>
                        {item.link.anchor}
                      </Flex>
                    </MagicLink>
                  </Box>
                )}
              </Box>
            </Box>
          ))}
        </Grid>
      </Container>
    </Box>
  )
}

export default DoubleColumnList
