import React from "react"
import { Box, Container, Grid, Heading, Text } from "@theme-ui/components"
import RichContentStructuredText from "../richContentStructuredText"
import {
  Accordion as AccordionWrapper,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import "react-tabs/style/react-tabs.css"

const Accordion = ({ kicker, title, items }) => {
  return (
    <Box >
      <Container>
        <Box
          sx={{
            py: [4],
            textAlign: "center",
          }}
        >
          <Box>
            <Text as="div" variant="kicker">
              <span>{kicker}</span>
            </Text>
          </Box>
          <Heading variant="h2" color="dark" sx={{ fontWeight: "500" }}>
            {title}
          </Heading>
        </Box>
        <Box
          sx={{
            ".react-tabs__tab-list": {
              display: "grid",
              width: "100%", 
              py:[4],
              overflowX: "auto",
              gridTemplateColumns: ["1fr 1fr 1fr"],
              gridGap: [4],
              border: "unset",
              ".react-tabs__tab": {
                "span" :{
                  color:"primary",
                },
                px:[3],
                py:[2],
                border: "1px solid",
                borderColor: "primary",
                borderRadius: "sm",
                "&.react-tabs__tab--selected": {
                  "span":{
                    color:"light"
                  },
                  color:"primary",
                  border: "1px solid!important",
                  borderColor: "primary",
                  backgroundColor: "primary",
                  "&:focus:after": {
                    content: "none",
                  },
                },
              },
            },
            ".react-tabs__tab-panel":{
              minHeight: "350px",
            }
          }}
        >
          <Tabs>
            <TabList>
              {items.map((item, index) => (
                <Tab>
                  <Box>
                    <Text variant="h5">{item.title}</Text>
                  </Box>
                </Tab>
              ))}
            </TabList>
            {items.map((item, index) => (
              <TabPanel>
                <Box sx={{p:{textAlign:"center", fontWeight:"400"} ,my: [6]}}>
                  <RichContentStructuredText text={item.body} customLink={item.link} />
                </Box>
              </TabPanel>
            ))}
          </Tabs>
        </Box>
      </Container>
    </Box>
  )
}

export default Accordion
