import React from "react"
import { Box, Flex, Grid, Heading, Text, Container } from "@theme-ui/components"
import RichContentStructuredText from "../richContentStructuredText"
import { GatsbyImage } from "gatsby-plugin-image"
import { number } from "prop-types"
import { MagicLink } from "../../utils/magicLink"
import { ChevronRight } from "react-feather"

const ImagesAndText = ({ kicker, title, body, images, link }) => {
  return (
    <Box sx={{ position: "relative", py: [4, 6], backgroundColor: "primary" }}>
      <Container>
        {console.log("link",link)}
        <Box
          sx={{
            py: [4],
            textAlign: "center",
          }}
        >
          <Box>
            <Text as="div" variant="kicker" sx={{color:"light"}}>
              <span>{kicker}</span>
            </Text>
          </Box>
          <Heading variant="h2" color="dark" sx={{ fontWeight: "500" }}>
            {title}
          </Heading>
        </Box>
        <Grid columns={[1, 1, ".4fr .6fr"]} gap={[3, 4, 5]}>
          <Box>
            <Box
              as="div"
              sx={{
                pr: [3],
                ul: {
                  display: "block",
                  columns: [1, 1, 1, 1],
                  li: {
                    mb: [0],
                    py: [2],
                    borderBottom: "1px solid",
                    borderColor: "dark",
                    color: "light",
                    p: {
                      color: "dark",
                      textAlign:"left"
                    },
                    "&:before": {
                      content: "'+'",
                      height: "6px",
                      minHeight: "6px",
                      maxHeight: "6px",
                      color: "dark",
                      width: "6px",
                      minWidth: "6px",
                      maxWidth: "6px",
                      backgroundColor: "trasparent",
                      mr: [3],
                    },
                  },
                },
                p: {
                  color: "light",
                },
                div: {
                  my: [0],
                },
              }}
            >
              <RichContentStructuredText text={body} />
            </Box>
            {link && (
              <Box sx={{ mt: [0, 2], py: [3] }}>
                <MagicLink
                  item={link}
                  locale={body.locale}
                  sx={{
                    fontWeight:"bold",
                    display:"inline-block",
                    color:"light",
                    textDecoration: "none",
                    "&:hover": {
                      textDecoration: "underline",
                      ".icon-wrapper": {
                        backgroundColor: "light",
                      },
                      svg: {
                        stroke: "primary",
                      },
                    },
                  }}
                >
                  <Flex sx={{ alignContent: "center", alignItems: "center" }}>
                    <Flex
                      className="icon-wrapper"
                      sx={{
                        mr: [2],
                        alignItems: "center",
                        justifyContent: "center",
                        p: [0],
                        border: "2px solid",
                        borderColor: "light",
                        svg: { strokeWidth: "3" },
                      }}
                    >
                      <ChevronRight size={16} />
                    </Flex>
                    {link.anchor}
                  </Flex>
                </MagicLink>
              </Box>
            )}
          </Box>
          <Grid
            sx={{
              gridTemplateRows: ["1fr"],
              height: "100%",
              width: "100%",
            }}
            columns={[1, 2]}
            gap={[5]}
          >
            {images && images[0] && images[0].gatsbyImageData && (
              <Box
                sx={{
                  gridRowStart: ["unset", "unset", 1],
                  gridRowEnd: ["unset", "unset", 1],
                  width: "100%",
                  height: "100%",
                  position: "relative",
                  ".gatsby-image-wrapper": {
                    overflow: "unset",
                    height: "100%",
                    width: "100%",
                    position: "relative",
                    img: {
                      objectFit: "contain!important",
                    },
                  },
                }}
              >
                <GatsbyImage image={images[0].gatsbyImageData} alt="" />
              </Box>
            )}
            {images && images[1] && images[1].gatsbyImageData && (
              <Box
                sx={{
                  mt: [0, 0, -8],
                  gridRowStart: ["unset", 1, 1],
                  gridRowEnd: ["unset", 1, 1],
                  gridColumn: [1, 2, 2],
                  width: "100%",
                  height: "100%",
                  position: "relative",
                  ".gatsby-image-wrapper": {
                    bottom: 0,
                    overflow: "unset",
                    height: "100%",
                    width: "100%",
                    position: "relative",
                    img: {
                      objectFit: "contain!important",
                    },
                  },
                }}
              >
                <GatsbyImage image={images[1].gatsbyImageData} alt="" />
              </Box>
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default ImagesAndText
