import React from "react"
import { Box, Text, Flex } from "@theme-ui/components"
import { StructuredText } from "react-datocms"
import {
  isHeading,
  isParagraph,
  isList,
  renderRule,
} from "datocms-structured-text-utils"
import ImageGallery from "./blocks/imageGallery"
import { MagicLink } from "../utils/magicLink"
import NumbersGroup from "./blocks/numbersGroup"
import Embed from "./blocks/embed"
import DoubleColumnListRichContent from "./blocks/doubleColumnListRichContent"
import { ChevronRight } from "react-feather"

const RichContentStructuredText = ({ text, theme, customLink = undefined }) => {
  const componentTheme = theme || "light"
  const light = componentTheme === "light" ? "light" : "dark"
  const dark = componentTheme === "light" ? "dark" : "light"
  return (
    <Box
      sx={{
        "*:first-child": {
          marginTop: 0,
        },
        "*:last-child": {
          marginBottom: 0,
        },
      }}
    >
      {text && text.value && (
        <StructuredText
          data={text}
          renderLinkToRecord={({ record }) => {
            switch (record.__typename) {
              case "DatoCmsInternalLink":
                return <MagicLink item={record} lcoale={record.locale} />
              default:
                return null
            }
          }}
          renderInlineRecord={({ record }) => {
            switch (record.__typename) {
              case "DatoCmsInternalLink":
                return (
                  <Box>
                    {<MagicLink item={record} lcoale={record.locale} />}
                  </Box>
                )
              default:
                return null
            }
          }}
          renderBlock={({ record }) => {
            // console.log(record)
            switch (record.__typename) {
              case "DatoCmsDoubleColumnList":
                return (
                  <Box >
                    <DoubleColumnListRichContent
                      kicker={record.kicker}
                      title={record.title}
                      list={record.list}
                      customLink={customLink}
                    />
                  </Box>
                )
              case "DatoCmsImageGallery":
                return (
                  <Box mt={5} mb={5}>
                    <ImageGallery images={record.images} key={record.id} />
                  </Box>
                )
              case "DatoCmsNumbersGroup":
                return (
                  <Box mt={5} mb={5}>
                    <NumbersGroup numbers={record.numbers} key={record.id} />
                  </Box>
                )
              case "DatoCmsEmbed":
                return <Embed code={record.code} fullWidth={record.fullWidth} />
              default:
                return null
            }
          }}
          customRules={[
            renderRule(
              isHeading,
              ({ adapter: { renderNode }, node, children, key }) => {
                return renderNode(
                  () => {
                    return (
                      <Text
                        as={`h${node.level}`}
                        variant={`h${node.level}`}
                        color={"primary"}
                      >
                        {children}
                      </Text>
                    )
                  },
                  { key },
                  children
                )
              }
            ),
            renderRule(
              isParagraph,
              ({ adapter: { renderNode }, node, children, key }) => {
                return renderNode(
                  () => {
                    return (
                      <Text as="p" mb={3} color={dark}>
                        {children}
                        <Box sx={{ textAlign: "center", with: "100%" }}>
                          {customLink && (
                            <Box sx={{ mt: [0, 2], py: [3] }}>
                              <MagicLink
                                item={customLink}
                                lcoale={customLink.locale}
                                sx={{
                                  display: "inline-block",
                                  textDecoration: "none",
                                  color: "primary",
                                  fontWeight:"bold",
                                  svg: {
                                    stroke: "primary",
                                  },
                                  "&:hover": {
                                    textDecoration: "underline",
                                    ".icon-wrapper": {
                                      backgroundColor: "primary",
                                    },
                                    svg: {
                                      stroke: "light",
                                    },
                                  },
                                }}
                              >
                                <Flex
                                  sx={{
                                    alignContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <Flex
                                    className="icon-wrapper"
                                    sx={{
                                      mr: [2],
                                      alignItems: "center",
                                      justifyContent: "center",
                                      p: [0],
                                      border: "2px solid",
                                      borderColor: "primary",
                                      svg: { strokeWidth: "3" },
                                    }}
                                  >
                                    <ChevronRight size={16} />
                                  </Flex>
                                  {customLink.anchor}
                                </Flex>
                              </MagicLink>
                            </Box>
                          )}
                        </Box>
                      </Text>
                    )
                  },
                  { key },
                  children
                )
              }
            ),
            renderRule(
              isList,
              ({ adapter: { renderNode }, node, children, key }) => {
                return renderNode(
                  () => {
                    return (
                      <Box mt={4}>
                        <Box
                          as={node.style === "numbered" ? "ol" : "ul"}
                          mb={3}
                          sx={{
                            color: dark,
                            listStyle: "none",
                            columns: [1, 1, 1, 2],
                            margin: 0,
                            padding: 0,
                            li: {
                              display: "flex",
                              counterIncrement: "inst",
                              mb: 3,
                              alignItems: "center",
                              "&::before": {
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                content: "counter(inst)",
                                marginRight: 4,
                                width: "50px",
                                height: "50px",
                                position: "relative",
                                lineHeight: "body",
                                backgroundColor: "transparent",
                                fontWeight: "bold",
                                border: "2px solid",
                                borderColor: "primary",
                                color: "primary",
                                borderRadius: "full",
                              },
                              p: {
                                mb: 0,
                              },
                            },
                          }}
                        >
                          {children}
                        </Box>
                      </Box>
                    )
                  },
                  { key },
                  children
                )
              }
            ),
          ]}
        />
      )}
    </Box>
  )
}

export default RichContentStructuredText
