import React from "react"
import { Box, Flex, Grid, Heading, Text, Container } from "@theme-ui/components"
import RichContentStructuredText from "../richContentStructuredText"
import { GatsbyImage } from "gatsby-plugin-image"
import { number } from "prop-types"
import { MagicLink } from "../../utils/magicLink"
import { keyframes } from "@emotion/react"
import { ChevronRight } from "react-feather"

const rotationKeyframe = keyframes({
  "0%": {
    transform: "rotate(0deg)",
  },
  "100%": {
    transform: "rotate(360deg)",
  },
})
const movementKeyframe = keyframes({
  "0%": {
    transform: "translate(10px,10px)",
  },
  "100%": {
    transform: "translate(-10px,-10px)",
  },
})
const fadeInKeyframe = keyframes({
  "0%": {
    transform: "scale(0)",
  },
  "40%": {
    transform: "scale(1)",
  },
  "100%": {
    transform: "scale(1)",
  },
})
const colorKeyframe = keyframes({
  "0%": {
    transform: "rotate(0deg)",
  },
  "100%": {
    transform: "rotate(360deg)",
  },
})

const ImageAndText = ({ image, body, alignment }) => {
  return (
    <Box
      sx={{
        pb: [4],
        position: "relative",
        backgroundColor: alignment === "left" && "greyBg",
      }}
    >
      <Container sx={{ py: [3, 3] }}>
        <Grid columns={["1fr", "1fr", "1fr 1fr"]} gap={[0, 0, 9]}>
          <Box
            sx={{
              flexDirection: "column",
              display: "flex",
              justifyContent: "center",
              py: [4],
              gridColumn: [1, 1, alignment === "left" ? 1 : 2],
              gridRow: [1, 1, 1],
            }}
          >
            <Box>
              {body.kicker && (
                <Text as="div" variant="kicker">
                  <span>{body.kicker}</span>
                </Text>
              )}
              <Heading
                as="h2"
                variant="h5"
                color="dark"
                sx={{ textTransform: "none" }}
              >
                {body.title}
              </Heading>
              <Box
                sx={{
                  "> div": {
                    display: "flex",
                    p: { maxWidth: "420px", color: "lightDark" },
                  },
                }}
              >
                <RichContentStructuredText text={body.body} />
              </Box>
            </Box>
            {body.link && (
              <Box sx={{ mt: [0, 2], py: [3] }}>
                <MagicLink
                  item={body.link}
                  lcoale={body.locale}
                  sx={{
                    textDecoration: "none",
                    fontWeight: "bold",
                    "&:hover": {
                      textDecoration: "underline",
                      ".icon-wrapper": {
                        backgroundColor: "primary",
                      },
                      svg: {
                        stroke: "light",
                      },
                    },
                  }}
                >
                  <Flex sx={{ alignContent: "center", alignItems: "center" }}>
                    <Flex
                      className="icon-wrapper"
                      sx={{
                        mr: [2],
                        alignItems: "center",
                        justifyContent: "center",
                        p: [0],
                        border: "2px solid",
                        borderColor: "primary",
                        svg: { strokeWidth: "3" },
                      }}
                    >
                      <ChevronRight size={16} />
                    </Flex>
                    {body.link.anchor}
                  </Flex>
                </MagicLink>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              gridColumn: [1, 1, alignment === "left" ? 2 : 1],
              gridRow: [2, 2, 1],
              display: "flex",
              justifyContent: "center",
              ".gatsby-image-wrapper": {
                width: "100%",
                height: "100%",
                img: {
                  height: "100%",
                },
              },
              svg: {
                height: "100%",
                width: "100%",
                "g[id^='rotate'] > g ": {
                  transformBox: "fill-box",
                  transformOrigin: "center",
                  animation: `${rotationKeyframe} 6s linear infinite alternate`,
                },
                "g[id^='movement']  > g,g[id^='movement']  > path": {
                  transformBox: "fill-box",
                  transformOrigin: "center",
                  animation: `${movementKeyframe} 4s linear infinite alternate`,
                },
                "g[id^='fade_in']  > g": {
                  transformBox: "fill-box",
                  transformOrigin: "center",
                  animation: `${fadeInKeyframe} 4s linear infinite alternate`,
                },
                "g[id^='color'] > g": {
                  transformBox: "fill-box",
                  transformOrigin: "center",
                  animation: `${colorKeyframe} 4s linear infinite alternate`,
                },
              },
            }}
          >
            {image && image.gatsbyImageData && (
              <GatsbyImage image={image.gatsbyImageData} alt="" />
            )}
          </Box>
        </Grid>
      </Container>
    </Box>
  )
}

export default ImageAndText
